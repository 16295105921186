import React from "react";
import PropTypes from "prop-types";
import Carousel from "nuka-carousel";

import "../../styles/testimonialsCarousel.css"

const Testimonials = ({ testimonials }) => (
    <span className="testimonialsWrapper">
        <h3 className='center'>Testimonials</h3>

        <Carousel
            autoplay={true}
            autoplayInterval={5000}
            autoGenerateStyleTag={false}
            pauseOnHover={true}
            withoutControls={true}
            swiping={false}
            transitionMode='scroll'
            slideWidth={1}
            speed={1500}
            dragging={false}
            wrapAround={true}
            heightMode='current'
            className='testimonialSlider'
        >
            {testimonials.map(
                ({ id, name, comment, url }) => (
                    <div key={id}>
                        <p className='review'>{comment}</p>
                        <a href={url} target="_blank" className='name' rel="noopener noreferrer">{name}</a>
                    </div>
                )
            )}
        </Carousel>
    </span>
);

Testimonials.propTypes = {
    testimonials: PropTypes.array.isRequired,
}

export default Testimonials;
