import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../components/seo"
import ServiceBoxes from "../components/index/serviceBoxes"
import BrandsCarousel from "../components/index/brandsCarousel"
import AboutBox from "../components/index/aboutBox"
import Testimonials from "../components/index/testimonialsCarousel"
import FooterQuoteForm from "../components/index/footerQuoteForm"

import "../styles/index.css"

const IndexPage = props => {
  const { directusHomepage } = useStaticQuery(
    graphql`
      {
        directusHomepage {
          id
          about_box
          ...Services
          ...Brands
          ...Testimonials
        }
      }

      fragment Services on DirectusHomepage {
        services {
          title
          points {
            point
          }
          icon {
            title
            localFile {
              publicURL
            }
          }
          page {
            url
          }
        }
      }

      fragment Brands on DirectusHomepage {
        brands {
          brand
          id
          logo {
            title
            localFile {
              publicURL
              greyscale: childImageSharp {
                resize(
                  duotone: { highlight: "#ffffff", shadow: "#777777" }
                  width: 200
                ) {
                  src
                  width
                  height
                }
              }
            }
          }
          website
        }
      }

      fragment Testimonials on DirectusHomepage {
        testimonials {
          id
          url
          name
          comment
        }
      }
    `
  )

  return (
    <>
      <SEO title="Home" />

      {/* <div className='content bg-black'>
          <HomeCarousel />
          <Taglines taglines={props.taglines} />
      </div> */}

      <div className="content bg-black">
        <ServiceBoxes services={directusHomepage.services} />
        {/* <QuoteModal
              className='quoteButton'
              buttonStyle={{
                  display: "block",
                  width: "12rem",
                  height: "3rem",
                  color: "white",
                  borderRadius: "0.33em",
                  border: "1px solid red",
                  background: "red",
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  margin: "1rem auto 1rem auto",
                  padding: "2px 5px",
              }}
          /> */}

        {/* <Taglines /> */}
      </div>

      <div className="content bg-white">
        <BrandsCarousel brands={directusHomepage.brands} />
      </div>

      <div className="content bg-black flexContainer">
        <div className="flexItem">
          <AboutBox body={directusHomepage.about_box} />
        </div>

        <div className="flexItem">
          <Testimonials testimonials={directusHomepage.testimonials} />
        </div>
      </div>

      <div className="content bg-white">
        <div className="flexItem">
          <FooterQuoteForm />
        </div>
      </div>
    </>
  )
}

export default IndexPage
