import React, { useState } from "react";
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import "../../styles/footerQuoteForm.css";

const transformErrors = (errors) => {
    return errors.map(error => {
        if (error.name === "format" && error.params.format === "contact") {
            error.message = "Please enter a Phone Number or Email Address."
        }

        if (error.name === "required") {
            error.message = "This field is required."
        }

        return error;
    })
}

const customFormats = {
    // 'contact': /(^\(?[2-9]\d{2}\)?[-.]?\d{3}[-.]?\d{4}$)|(^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$)/gim
    'contact': /(^\(?[2-9]\d{2}\)?[-.]?\d{3}[-.]?\d{4}$)|(^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$)/gim
}

const schema = {
    definitions: {},
    title: "Request a Quote",
    type: "object",
    properties: {
        customer: {
            type: "object",
            title: "First, tell us who you are.",
            required: ["name"],
            properties: {
                name: {
                    type: "string",
                    title: "Name"
                },
                contact: {
                    type: "string",
                    title: "Contact",
                    format: "contact"
                },
            }
        },
        comments: {
            type: "object",
            title: "Then, tell us what you're looking for:",
            properties: {
                comment: {
                    "type": "string",
                    "title": "Comments"
                }
            }
        },
    },
    dependencies: {

    }

};

const uiSchema = {
    customer: {
        name: {
            "ui:placeholder": "Name or Company",
            "ui:options": {
                label: false,
            }
        },
        contact: {
            "ui:placeholder": "Email/Phone",
            "ui:options": {
                label: false,
            }
        }
    },
    comments: {
        comment: {
            "ui:widget": "textarea",
            "ui:placeholder": "Give us some details about what you'd like produced. We'll nail down the specifics later.",
            "ui:options": {
                label: false,
            }
        }
    }

}

const ObjectFieldTemplate = (props) => {
    const { TitleField, DescriptionField } = props;

    return (
        <>
            {(props.uiSchema["ui:title"] || props.title) && (
                <TitleField
                    id={`${props.idSchema.$id}__title`}
                    title={props.title || props.uiSchema["ui:title"]}
                    required={props.required}
                    formContext={props.formContext}
                />
            )}

            {props.description && (
                <DescriptionField
                    id={`${props.idSchema.$id}__description`}
                    description={props.description}
                    formContext={props.formContext}
                />
            )}

            <div role="group" id={props.idSchema.$id}>
                {props.properties.map(prop => prop.content)}
            </div>
        </>
    )
}

const ResultsTemplate = (props) => {
    const { title, body } = props;

    return (
        <div className="footerQuoteForm">
            <h2>{title}</h2>
            {Array.isArray(body) && body.map((line, key) => <p key={key}>{line}</p>)}
            {typeof body === "string" && <p>{body}</p>}
            <style jsx>{`
                h2, p, div {
                    text-align: center;
                }
            `}</style>
        </div>
    )
}
ResultsTemplate.propTypes = {
    title: PropTypes.string,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

const FooterQuoteForm = (props) => {
    const [submitResults, setSubmitResults] = useState({ submitted: false, success: false, error: null });

    const onSubmit = (formData, e) => {
        let data = {
            form: {
                ...formData.formData.customer,
                ...formData.formData.comments
            }
        }
        
        fetch("/api/forms/submit/quoteForm", {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer 518896ef6bd18dadcd6aa1e829b888"
            },
            method: "POST",
            body: JSON.stringify(data)
        })
            .then(res => {
                console.log("Results submitted: ", res);
                if (res.status === 200) {
                    setSubmitResults({ submitted: true, success: true, error: null });
                } else {
                    console.error("Results failed to submit:", res.statusText);
                    setSubmitResults({ submitted: true, success: false, error: res.status+' '+res.statusText });
                }
            })
            .catch(error => {
                setSubmitResults({ submitted: true, success: false, error: error });
            });
    }

    if (submitResults.submitted && !submitResults.success) {
        return (
            <ResultsTemplate
                title="Oops! Something went wrong."
                body={["Sorry, something broke here.", "Please give us a call and we'd be happy to assist over the phone.", "Error: "+submitResults.error]}
            />
        )
    }

    if (submitResults.submitted && submitResults.success) {
        return (
            <ResultsTemplate
                title="Thank you for your interest!"
                body="We'll be in touch soon."
            />
        )
    }

    return (
        <div className="footerQuoteForm">
            <Form
                schema={schema}
                uiSchema={uiSchema}
                // onChange={log("changed")}
                onSubmit={onSubmit}
                // onError={log("errors")}
                noHtml5Validate={true}
                showErrorList={false}
                ObjectFieldTemplate={ObjectFieldTemplate}
                customFormats={customFormats}
                transformErrors={transformErrors}
            />
        </div>
    )
}

export default FooterQuoteForm;