import React from "react"

import Carousel from "nuka-carousel"
import "../../styles/brandsCarousel.css"

const BrandsCarousel = ({ brands }) => {
  return (
    <Carousel
      autoplay={true}
      autoplayInterval={7000}
      autoGenerateStyleTag={false}
      pauseOnHover={false}
      withoutControls={true}
      swiping={false}
      transitionMode="fade"
      slidesToShow={4}
      slideWidth={1}
      slidesToScroll={4}
      speed={500}
      dragging={false}
      wrapAround={true}
      heightMode="current"
      initialSlideHeight={100}
      className="brandsSlider"
    >
      {brands.map(({ id, brand, website, logo }) => {
        if (logo && logo.localFile && logo.localFile.publicURL) {
          return (
            <img
              key={id}
              src={logo.localFile.greyscale.resize.src}
              alt={`${brand}: ${website}`}
            />
          )
        } else {
          return null
        }
      })}
    </Carousel>
  )
}

export default BrandsCarousel
