import React from "react";
import { Link } from "gatsby";

import "../../styles/serviceBoxes.css";

const ServiceBoxes = ({ services }) => (
    <div className='servicesContainer'>
        {services.map(service => (
            <Link key={service.title} to={(service.page) ? service.page.url  : '#'} className='serviceBox center'>
                    <div>
                        <img src={service.icon.localFile.publicURL} alt={service.title} />{" "}
                        <h3>{service.title.value}</h3>
                    </div>
                    <ul>
                        {service.points.map(({ point },  index) => (
                            <li
                                key={index}
                                dangerouslySetInnerHTML={{
                                    __html: point
                                }}
                            />
                        ))}
                    </ul>
            </Link>
        ))}
    </div>
);

export default ServiceBoxes;
