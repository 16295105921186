import React from "react";
import PropTypes from 'prop-types';

import "../../styles/aboutBox.css"

const AboutBox = ({ body }) => (
    <div className="aboutBox" dangerouslySetInnerHTML={{  __html: body }}>
    </div>
);

AboutBox.propTypes = {
    body: PropTypes.string.isRequired,
}

export default AboutBox;
